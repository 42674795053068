(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/socket/assets/javascripts/socket.js') >= 0) return;  svs.modules.push('/components/core/socket/assets/javascripts/socket.js');


svs = svs || {};
svs.core = svs.core || {};
svs.core.socket = svs.core.socket || {};
svs.core.socket.data = svs.core.socket.data || {};
svs.core.config = svs.core.config || {};
svs.core.config.data = svs.core.config.data || {};
svs.core.config.data.svsconfig = svs.core.config.data.svsconfig || {};
svs.core.config.data.svsconfig.socketIO = svs.core.config.data.svsconfig
  .socketIO || {
  showAlertDelay: 8000,
  reconnectWait: 500
};

(function(svs, config, io, window) {
  'use strict';

  var DEAD_TIMEOUT = config.showAlertDelay || 4000;

  var RECONNECT_WAIT = config.reconnectWait || 500;

  var HEARTBEAT_TIMEOUT = config.heartbeatTimeout || 1000;

  svs.core.Socket = function(modelData) {
    var mySocket = null;
    var transportType;
    var socketId;
    var subscriptions = [];
    var callbacks = [];
    var connectUrl = modelData.connectUrl;
    var log = new svs.core.log.Logger('svs.core.Socket');
    var deadTimeout;
    var heartbeatDiff = 0;
    var lastInterval = getTime();
    var heartbeat = setInterval(intervalHeartbeat, 1000);
    var autoReconnect = true;

    $(window).one('beforeunload', function() {
      if (mySocket && mySocket.connected) {
        mySocket.off();
        mySocket.disconnect();
      }
    });

    function connect() {
      if (mySocket) {
        mySocket.off();
      }

      log.debug('socket.connect(' + connectUrl + ')');

      socketId = null;
      transportType = null;

      mySocket = io.connect(connectUrl, {
        forceNew: true,
        reconnection: false,
        transports: ['websocket', 'polling']
      });

      mySocket.on('connect', function() {
        if (this.io && this.io.engine) {
          if (this.io.engine.transport) {
            transportType = this.io.engine.transport.name;
            socketId = this.io.engine.id;
          }
          log.info('Connected.', {
            metadata: { sockId: socketId, transType: transportType }
          });

          this.io.engine.on('upgrading', function(obj) {
            transportType = obj.query.transport;
            socketId = obj.query.sid;
            log.info('upgraded.', {
              metadata: { sockId: socketId, transType: transportType }
            });
          });
        }

        stopDeadCountdown();

        emitAllSubscriptions();

        runCallbacks('connect', arguments);
      });

      mySocket.on('disconnect', function() {
        log.info('Disconnected.', {
          metadata: { sockId: socketId, transType: transportType }
        });

        startDeadCountdown();
        if (!autoReconnect) {
          history.go(0);
        }
        setTimeout(function() {
          log.debug('Trying to reestablish connection.');

          connect();
        }, RECONNECT_WAIT);

        runCallbacks('disconnect', arguments);
      });

      mySocket.on('realitycheck', function(data) {
        log.debug('Recieved rcmsg: ' + data.messageId);
        runCallbacks('realitycheck', [data]); 
      });

      mySocket.on('update', function(data) {
        log.debug('Socket got update: ', data, {
          metadata: { sockId: socketId, transType: transportType }
        });
        handleCallback(data.path, data.value, data.timestamp);

        runCallbacks('update', arguments);
      });

      mySocket.on('connect_error', function(error) {
        if (this.io && this.io.engine && this.io.engine.transport) {
          transportType = this.io.engine.transport.name;
          socketId = this.io.engine.id;
        }

        if (error === 'client not handshaken') {
          log.info('client not handshaken.', {
            metadata: { sockId: socketId, transType: transportType }
          });

          startDeadCountdown();

          dispose();

          setTimeout(function() {
            log.debug('Trying to reestablish connection.');

            connect();
          }, RECONNECT_WAIT);
        } else if (heartbeatDiff > HEARTBEAT_TIMEOUT) {
          if (!autoReconnect) {
            history.go(0);
          }
          log.debug('Trying to reestablish connection.');
          connect();
        } else {
          log.warn('Got error: ', error, {
            metadata: { sockId: socketId, transType: transportType }
          });

          startDeadCountdown();

          runCallbacks('connect_error', arguments);
        }
      });

      mySocket.on('cerror', function() {
        runCallbacks('cerror', arguments);
      });
    }

    function dispose() {
      if (mySocket) {
        mySocket.off('disconnect');

        mySocket.disconnect();

        mySocket = null;
      }
    }

    function emitAllSubscriptions() {
      log.debug('EmitAllSubscriptions().', {
        metadata: { sockId: socketId, transType: transportType }
      });
      for (var i = 0; i < subscriptions.length; i++) {
        var subscription = subscriptions[i];
        var params = {
          url: subscription.path.url,
          sendInitialUpdate: subscription.sendInitialUpdate,
          backendFallback: subscription.backendFallback
        };

        log.debug('Emit(subscribe)', params);
        mySocket.emit('subscribe', params);
      }
    }

    function handleCallback(url, data, timestamp) {
      log.debug('HandleCallback(' + url + ', ' + timestamp + ')');

      var matchingSubscriptions = subscriptions.filter(function(subscription) {
        return subscription.path.url.split('?')[0] === url.split('?')[0];
      });

      matchingSubscriptions.forEach(function(subscription) {
        if (subscription) {
          subscription.callback.call(subscription.context, data, timestamp);
        }
      });
    }

    function runCallbacks(type, args) {
      if (!callbacks.length) {
        return;
      }

      callbacks
        .filter(function(callback) {
          return callback.type === type;
        })
        .forEach(function(callback) {
          callback.fn.apply(callback.context, args);
        });
    }

    function startDeadCountdown() {
      if (!deadTimeout && heartbeatDiff < HEARTBEAT_TIMEOUT) {
        deadTimeout = setTimeout(function() {
          log.info('Showing socket not connected error.', {
            metadata: { sockId: socketId, transType: transportType }
          }); 
          runCallbacks('dead', {});
          deadTimeout = null;
        }, DEAD_TIMEOUT);
      }
    }

    function stopDeadCountdown() {
      if (deadTimeout) {
        clearTimeout(deadTimeout);
        deadTimeout = null;
      }
    }

    function subscribe(
      path,
      callback,
      context,
      sendInitialUpdate,
      autoreconnect,
      backendFallback
    ) {
      log.debug('socket.subscribe(' + path + ').');
      if (autoreconnect !== undefined) {
        autoReconnect = autoreconnect;
      }
      if (!path || !context) {
        throw new Error('svs.core.Socket.subscribe needs path and context.');
      }

      var subscriptionObject = {
        path: { url: path },
        callback: callback,
        context: context,
        sendInitialUpdate:
          sendInitialUpdate === false ? sendInitialUpdate : true,
        backendFallback: Boolean(backendFallback)
      };

      for (var i = 0; i < subscriptions.length; i++) {
        if (
          subscriptions[i].path.url === subscriptionObject.path.url &&
          subscriptions[i].callback === subscriptionObject.callback &&
          subscriptions[i].context === subscriptionObject.context
        ) {
          return;
        }
      }

      subscriptions.push(subscriptionObject);
      if (!mySocket) {
        connect();
      } else if (mySocket.connected) {
        log.debug('Emit(subscribe, ' + path + ')', {
          metadata: { sockId: socketId, transType: transportType }
        });
        mySocket.emit('subscribe', {
          url: path,
          sendInitialUpdate:
            sendInitialUpdate === false ? sendInitialUpdate : true,
          backendFallback: Boolean(backendFallback)
        });
      }
    }

    function isListenerForSubscription(callback, subscription) {
      if (
        typeof callback === 'function' &&
        subscription.callback === callback
      ) {
        return true;
      } else if (
        Object.prototype.hasOwnProperty.call(callback, 'cid') &&
        subscription.context.cid === callback.cid
      ) {
        log.debug('unsubscribe(path, context) is deprecated. Please replace context with callback');
        return true;
      }
    }

    function unsubscribe(path, callback) {
      log.debug('socket.unsubscribe(' +
          path +
          ', ' +
          (callback && callback.name || 'undefined') +
          ')');

      if (!path || !callback) {
        throw new Error('svs.core.Socket.unsubscribe requires path and callback arguments.');
      }

      var i = subscriptions.length;
      while (i) {
        i--;
        if (subscriptions[i].path.url === path) {
          if (isListenerForSubscription(callback, subscriptions[i])) {
            subscriptions.splice(i, 1);

            if (
              subscriptions.filter(function(sub) {
                return sub.path.url === path;
              }).length === 0
            ) {
              log.debug('Emit(unsubscribe, ' + path + ')', {
                metadata: { sockId: socketId, transType: transportType }
              });
              mySocket.emit('unsubscribe', {
                url: path
              });
            }
          }
        }
      }
    }

    function onEvent(type, fn, context) {
      if (!type || !fn) {
        throw new Error('svs.core.Socket.on needs type and fn for the callback.');
      }

      if (!mySocket) {
        connect();
      }

      callbacks.push({
        type: type,
        fn: fn,
        context: context
      });
    }

    function offEvent(type, fn) {
      if (!type || !fn) {
        throw new Error('svs.core.Socket.off needs type and fn for the callback.');
      }

      for (var i = 0; i < callbacks.length; i++) {
        if (callbacks[i].type === type && callbacks[i].fn === fn) {
          callbacks.splice(i, 1);
          return;
        }
      }
    }

    function clearTimers() {
      stopDeadCountdown();
      clearInterval(heartbeat);
      heartbeat = setInterval(intervalHeartbeat, 1000);
    }

    function getTime() {
      return new Date().getTime();
    }

    function intervalHeartbeat() {
      var now = getTime();
      heartbeatDiff = now - lastInterval - 1000; 
      lastInterval = now;
      if (heartbeatDiff > HEARTBEAT_TIMEOUT) {
        clearTimers();
      }
    }

    return {
      subscribe: subscribe,
      unsubscribe: unsubscribe,
      on: onEvent,
      off: offEvent
    };
  };

  svs.core.socket = new svs.core.Socket(svs.core.socket.data);
})(svs, svs.core.config.data.svsconfig.socketIO, io, window);


 })(window);